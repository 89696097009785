<template>
  <div class="product-info">
    <div class="big-title">
      <span>PRODUCTS</span>
    </div>
    <div class="product-info-text">
      In the realm of gaming, our quest is to craft innovative, visually captivating and endlessly replayable innovation, where joy and happiness find their eternal abode.    </div>
    <ul class="product-shopping">
      <li v-for="(item,index) in listData" :key="index" :class="[productIndex === index ? 'active' : '']" @click="handleProduct(index)">
        <div class="box">
          <div class="icon">
            <img :src="require('@/assets/images/'+(productIndex === index ? item.iconActive : item.icon))" alt="">
          </div>
          <div class="text">{{ item.title }}</div>
        </div>
      </li>
    </ul>
    <div class="product-img">
      <div class="product-img-box1" v-if="productIndex == 0">
        <div class="flex_img">
          <div class="left-img">
            <img v-lazy="require('@/assets/images/pc/product_xiyou.png')" @click="jumpPage('/journey-to-the-west')" alt="">
            <img v-lazy="require('@/assets/images/pc/product_conglin.png')" @click="jumpPage('/come-on-bite-me!')" class="last-img" alt="">
          </div>
          <div class="middle-img">
            <img v-lazy="require('@/assets/images/pc/product_caif.png')" @click="jumpPage('/tailor-master')" alt="">
            <img v-lazy="require('@/assets/images/pc/product_wod.png')" @click="jumpPage('/win-or-die')" class="last-img" alt="">

          </div>
          <div class="right-img">
            <img v-lazy="require('@/assets/images/pc/product_witness.png')" @click="jumpPage('/safari-witness')" alt="">
            <img v-lazy="require('@/assets/images/pc/product_aigame.png')" @click="jumpPage('/aiCard')" class="last-img" alt="">
          </div>
        </div>
      </div>
      <div class="product-img-box2" v-else-if="productIndex == 1">
        <div class="flex_img">
          <div class="left-img">
            <img v-lazy="require('@/assets/images/pc/product_xiyou.png')" @click="jumpPage('/journey-to-the-west')" alt="">
            <img v-lazy="require('@/assets/images/pc/product_conglin.png')" @click="jumpPage('/come-on-bite-me!')" class="last-img" alt="">
          </div>
          <div class="middle-img">
            <img v-lazy="require('@/assets/images/pc/product_caif.png')" @click="jumpPage('/tailor-master')" alt="">
            <img v-lazy="require('@/assets/images/pc/product_wod.png')" @click="jumpPage('/win-or-die')" class="last-img" alt="">
          </div>
          <div class="right-img">
            <img v-lazy="require('@/assets/images/pc/product_witness.png')" @click="jumpPage('/safari-witness')" alt="">
          </div>
        </div>
      </div>
      <div class="game_img" v-else>
        <img v-lazy="require('@/assets/images/pc/product_game_banner.png')" alt="" @click="jumpPage('/aiCard')">
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ref, watch } from "vue";

import { useStore } from 'vuex';
import { useRouter } from "vue-router"

export default {
  name: "CCProducts",
  components: {
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    let productIndex = ref(0)

    watch(() => store.state.productIndex, (newVal, oldVal) => {
      productIndex.value = newVal
    })
    const handleProduct = (index) => {
      productIndex.value = index
    }
    const listData = [
      {
        title: 'ALL GAMES',
        icon: "pc/product_all.png",
        iconActive: "pc/product_all2.png"
      },
      {
        title: 'BOARD GAME',
        icon: "pc/product_board.png",
        iconActive: "pc/product_board2.png"
      },
      {
        title: 'DIGITAL GAME',
        icon: "pc/product_game.png",
        iconActive: "pc/product_game2.png"
      },
    ]

    const jumpPage = (path) => {
      router.push({
        path
      })
    }
    return {
      listData,
      productIndex,
      handleProduct,
      jumpPage
    }
  },
  methods: {

  }
}
</script>

<style scoped lang="less">
.product-info {
  font-family: "Aleo Regular";
  padding: 20px 0;
  width: 75%;
  margin: auto;
  .big-title {
    color: #000;
    span {
      padding: 0 40px;
      font-size: 36px;
      position: relative;
      line-height: 90px;
      height: 90px;
      display: inline-block;
      font-family: "Aleo Regular";
      &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 12px;
        background: #30affd;
        border-radius: 2px;
      }
    }

  }
  .product-info-text {
    font-size: 24px;
    color: #505050;
    line-height: 40px;
    width: 80%;
    margin: 30px auto 0;
  }
  .product-shopping {
    font-size: 18px;
    box-shadow: 4px 4px 4px 4px #e4e4e4;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    padding: 40px 30px;
    margin-top: 54px;
    li {
      width: 33.3%;
      position: relative;
      cursor: pointer;
      height: 110px;
      border-right: 1px solid #f1f1f1;
      display: flex;
      align-items: center;
      justify-content: center;
      .box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .icon {
          width: 60px;
          height: 55px;
          img {
            width: 50px;
            max-height: 100%;
          }
        }
        .text {
          font-size: 18px;
          margin-top: 14px;
        }
      }
      &.active {
        .box {
          content: '';
          position: absolute;
          left: 50%;
          top: -60px;
          transform: translateX(-50%);
          background: #30affe;
          color: #fff;
          width: 80%;
          height: 240px;
          z-index: 9;
          border-radius: 4px;
        }
      }
      &:last-child {
        border: none;
        .icon {
          img {
            width: 58px;
            margin-top: 10px;
          }
        }
         &.active {
           .box {
           }
         }
      }
    }

  }
  .game_img {
    width: 100%;
    margin: 80px auto 60px;
    img {
      width: 100%;
      cursor: pointer;
    }
  }
  .flex_img {
    display: flex;
    justify-content: space-between;
    margin-top: 80px;
    .left-img, .middle-img, .right-img {
      width: 32%;
      img {
        width: 100%;
        cursor: pointer;
      }
      .last-img {
        margin-top: 30px;
      }
    }
  }

  .product-img-box1,
  .product-img-box2 {

    img {
      //min-height: 3.4984rem;
      //height: 4.372rem;
    }
  }
}
</style>