// 判断是否为移动端

export function CheckMobileDevice() {
    const userAgent = navigator.userAgent;
    const mobileAgents = ['Android', 'iPhone', 'Symbian', 'WindowsPhone', 'iPod', 'BlackBerry', 'Windows CE'];
    let is_mobile = false
    mobileAgents.map((value) => {
        if (userAgent.indexOf(value) > -1) {
            is_mobile = true
        }
    })
    return is_mobile
}


// 改变日期格式
export function changeDateFormat (dateTimeStr: any) {
    const date = new Date(dateTimeStr);

    // 获取日期和时间的各个部分
    const year = date.getFullYear();
    let month: number | string = date.getMonth() + 1; // 月份是从0开始的，所以需要加1
    let day: number | string = date.getDate();
    let hours: number | string = date.getHours();

    month = month > 9 ? month : '0'+month
    day = day > 9 ? day : '0'+day
    hours = hours > 9 ? hours : '0'+hours

    // 格式化日期和时间
    return `${year}/${month}/${day}`;
}
export function isValidEmailSuffix(email: string) {
    // 正则表达式匹配常见的域名后缀
    const validSuffixRegex = /\.(com|net|org|edu|gov)$/i;

    // 提取邮箱地址中的域名后缀部分
    const suffix = email.split('@')[1];

    // 进行匹配
    return validSuffixRegex.test(suffix);
}
// 一维数组转换二维数组
export function toTwoDimensionalArray(arr: any[], length: number) {
    const result: any[] = [];
    for (let i = 0; i < arr.length; i += length) {
        result.push(arr.slice(i, i + length));
    }
    return result;
}

export function getNewTypeText(type: number) {
    if(type == 1) {
        return 'Board Game'
    }else {
        return 'Digital Game'
    }
}