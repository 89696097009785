<template>
  <div id="app">
    <div sticky-container class="sticky-container">
      <div v-if="initNextTick" v-sticky="true" class="sticky" sticky-offset="{top: 0}" sticky-side="both" on-stick="onStick" sticky-z-index="20">
        <div class="flexBox">
          <div class="header-box header-component-m" v-if="CheckMobileDevice()">
            <MobileHeader @clickMenu="OnClickMenu" @clickChildMenu="OnClickChildMenu"></MobileHeader>
          </div>
          <div class="header-box  header-component" v-else>
            <CCHeader @clickMenu="OnClickMenu" @clickChildMenu="OnClickChildMenu" />
          </div>
        </div>
      </div>
<!--        <div class="header-component-m" v-if="CheckMobileDevice()">-->
<!--          <MobileHeader @clickMenu="OnClickMenu" @clickChildMenu="OnClickChildMenu"></MobileHeader>-->
<!--        </div>-->
<!--        <div class="header-component" v-else>-->
<!--          <CCHeader @clickMenu="OnClickMenu" @clickChildMenu="OnClickChildMenu" />-->
<!--        </div>-->
      <div class="app-view">
        <router-view />
      </div>
      <div :class="['scrollTop', scrollState ? 'show' : '', CheckMobileDevice() ? 'mobile_back' : '']" @click="clickScrollTop()">
        <img src="@/assets/images/back.png" alt="" >
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import './assets/styles/font.css';
import './assets/styles/app.css';
import { CheckMobileDevice } from "@/utils/handle";
import {onMounted, nextTick, watch, ref} from "vue";


import CCHeader from "@/components/CCHeader.vue";
import MobileHeader from "@/components/mobile/CCHeader.vue";

import router from './router'

import {productList} from "@/api";

const initNextTick = ref(false)

// const customRouter = async () => {
//     const {data } = await productList({})
//     const list:any[] = data.list || []
//     list.forEach(((item: any) => {
//       console.log(item.product_link)
//       router.addRoute({
//         path: `/${item.product_link}`,
//         name: item.product_link,
//         component: () => import('@/views/product/index.vue')
//       });
//     }))
// }
//
// customRouter()

const OnClickMenu = (id) => {


  let anchor = document.getElementById('sel' + id);
  anchor?.scrollIntoView({ behavior: 'smooth'});
}


const OnClickChildMenu = (uri) => {
  console.log('OnClickChildMenu.id:' + uri)

  resetUri(uri);

  if (uri.length == 0 || uri.split('/').length === 2) {
    window.location.href = '/';
    return;
  }

  router.push({ path: uri })
}

const resetUri = (uri: string) => {
  let jsObj = JSON.parse('{"uri":"' + uri + '"}');
  localStorage.setItem('ccenv', JSON.stringify(jsObj));
}

const onDebug = () => {
  nextTick(() => {
    let timer = setInterval(() => {
      if (document.readyState !== 'complete') {
        return
      }

      let sel: Element = document.createElement('div');
      // sel.style = 'font-size: 5.0px;';
      sel.setAttribute('id', 'testEl');
      sel.setAttribute('style', 'font-size: 10.0px;')
      document.body.appendChild(sel);


      // 计算出放大后的字体
      var scaledSize = parseFloat(window.getComputedStyle(sel, null).getPropertyValue('font-size'));
      // alert(window.getComputedStyle(sel, null).getPropertyValue('font-size'));
      // console.log('XXX=>' + window.getComputedStyle(sel, null).getPropertyValue('font-size'))
      // alert (window.getComputedStyle(sel, null).getPropertyValue('font-size'))

      // 计算原字体和放大后字体的比例1.25
      var scaleFactor = 10.0 / scaledSize;
      console.log('scaledSize: ' + scaledSize + ' , scaleFactor--->' + scaleFactor)
      if (scaleFactor !== 2) {
        console.log('resize to: ' + scaleFactor)
        // alert('scaledSize=' + scaledSize + ',scaleFactor=' + scaleFactor)
        // document.body.removeChild(sel);
        // scaleFactor = 10 / scaleFactor
      } else {
        // document.body.removeChild(sel);
        window.clearInterval(timer)
        return
      }

      // 取html元素的字体大小
      // 注意，这个大小也经过缩放了
      // 所以下方计算的时候 *scaledFontSize是原来的html字体大小
      // 再次 *scaledFontSize才是我们要设置的大小
      var originRootFontSize = parseFloat(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'));
      // alert(window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size'));
      let newSize = originRootFontSize / scaleFactor;
      document.documentElement.style.fontSize = newSize + 'px';
      // alert(newSize);

      const fs = window.getComputedStyle(document.documentElement, null).getPropertyValue('font-size');

      console.log('scaledSize: ' + scaledSize + ' scaleFactor: ' + scaleFactor + ' originRootFontSize: ' + originRootFontSize + ' newSize: ' + newSize)

      console.log('fs:' + fs)
      window.clearInterval(timer)
    }, 100)
  })
}

const handleResize = () => {
  // 1rem == 20px
  setRem()
  // console.log('isMobile:', Vue.config.globalProperties.$mobile)
}

const setRem = () => {
  let winWidth = document.documentElement.clientWidth
  // let winWidth = window.innerWidth
  let scale = winWidth / 1920
  scale = scale > 1 ? 1 : scale;

  const realFont = scale * 100
  document.documentElement.style.fontSize = realFont + 'px'
  // console.log('winWidth:' + winWidth + ' ,scale:', scale, ' ,setRem:', realFont)
}

const isMobile = () => {
  return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i) !== null
}

const handleComplete = () => {
  // if (typeof WeixinJSBridge == undefined) {
  //   return
  // }
  // document.addEventListener("WeixinJSBridgeReady", function () {
  //   WeixinJSBridge.invoke("setFontSizeCallback", {
  //     fontSize: '2'
  //   });
  // }, false);

  nextTick(() => {
    let timer = setInterval(() => {
      if (document.readyState !== 'complete') {
        return
      }
      let sel = createScaleElement()
      setFont(sel)
      document.body.removeChild(sel);
      window.clearInterval(timer)
    }, 100)
  })
}

const createScaleElement = () => {
  let scaleDom = document.createElement('div')
  scaleDom.id = 'testdiv'
  scaleDom.style.cssText = 'width:1rem;height:0;overflow:hidden;position:absolute;z-index:-2;visibility:hidden;'
  document.body.appendChild(scaleDom)
  return scaleDom
}

const setFont = (sel) => {
  let scale = toScale(sel)
  // console.log('sacle=>' + scale)
  let winWidth = document.documentElement.clientWidth
  // let winWidth = window.innerWidth
  let designWidth = 1920 || 640
  if (winWidth > designWidth) {
    winWidth = designWidth
  }
  let fs = (winWidth / designWidth * 100) * scale
  // console.log('winWidth:' + winWidth + ' , fs:' + fs)

  document.documentElement.style.fontSize = fs + 'px'
}

const toScale = (scaleDom) => {
  let htmlFontSize = getOriginalHtmlFontSize()
  let instanceWidth = scaleDom.offsetWidth
  let scale = 1

  if (window.getComputedStyle) {
    instanceWidth = window.getComputedStyle(scaleDom).width
    // console.log('instanceWidth:' + instanceWidth)
  }

  htmlFontSize = toNum(htmlFontSize)
  instanceWidth = toNum(instanceWidth)
  // console.log('instanceWidth:' + instanceWidth + ' , htmlFontSize:' + htmlFontSize)

  if (
    (typeof htmlFontSize == 'number' && htmlFontSize != 0) &&
    (typeof instanceWidth == 'number' && instanceWidth != 0)
  ) {
    if (Math.abs(htmlFontSize - instanceWidth) < 0.1) {
      return 1
    }
    scale = htmlFontSize / instanceWidth
  }
  return scale
}

const getOriginalHtmlFontSize = () => {
  let rootDom = document.querySelector('html')
  let fs = rootDom?.style?.fontSize
  let fontSize = fs || 16
  return fontSize
}

const toNum = (fontSize) => {
  if (typeof fontSize === 'string') {
    fontSize = fontSize.replace('px', '')
    return Number(fontSize)
  }
  return fontSize
}


export default {
  name: 'App',
  components: {MobileHeader, CCHeader },
  setup() {
    let showHeader = ref(router.options.history.location != '/catalogue');
    const scrollState = ref(false)


    watch(() => router,(newPath, oldPath) => {


      showHeader.value = newPath.options.history.location != '/catalogue'
    },{ immediate: true });
    onMounted(() => {

      setTimeout(() => {
        initNextTick.value = true
      }, 200)
      handleResize()
      window.addEventListener("resize", handleResize)
      const bannerHeight = CheckMobileDevice() ? 120 : 120
      let className = CheckMobileDevice()  ? 'header-component-bg-m' : 'header-component-bg'
      document.addEventListener('scroll', (event) => {
        const screenHeight = document.documentElement.scrollTop || document.body.scrollTop;

        if(screenHeight > 260) {
          scrollState.value = true
        }else {
          scrollState.value = false
        }
        if( document.getElementsByClassName('header-box').length > 0) {
          if(screenHeight > bannerHeight) {
            document.getElementsByClassName('header-box')[0].classList.add(className)
          }else {
            document.getElementsByClassName('header-box')[0].classList.remove( className)
          }
        }

      })

      // onDebug()
      handleComplete()
      let jsStr = localStorage.getItem('ccenv') || '{"uri":""}'
      let ccenv = JSON.parse(jsStr)
      // console.log('ccenv ===> ' + ccenv.uri)

      if (ccenv.uri == undefined || ccenv.uri.split('/').length !== 2) {
        return;
      }



      nextTick(() => {

        let timer = setInterval(() => {
          if (document.readyState !== 'complete') {
            return
          }
          resetUri('');
          OnClickMenu(2);
          window.clearInterval(timer)
        }, 100)
      })
    })


    const clickScrollTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth' // 可选，平滑滚动
      });
    }
    return { OnClickMenu, OnClickChildMenu, CheckMobileDevice, showHeader, initNextTick, scrollState, clickScrollTop}
  },
}
</script>

<style lang="less">
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
.sticky-container {
  overflow: hidden;
  background: #f6f6f6;
  width: 100%;
}
.transparent_container {
  .sticky {
    background-color:rgba(38,45,51,0.5);
  }
}


.sticky-container p {
  //font-size: 20px;
  //margin: 20px;
}

.sticky {
  //background: rgba(38, 45, 51, 0.6) ;
  align-items: center;
}

.app-view {
  position: relative;
}
.scrollTop {
  position: fixed;
  bottom: 10%;
  right: 5%;
  z-index: 88;
  display: none;
  img {
    width:  50px;
  }
  &.show {
    display: flex;

  }
  &.mobile_back {
    img {
      width: 1.4rem;
    }
  }
}

.header-component {
  //position: fixed;
  //top: 0;
  //left: 0;
  width: 100vw;
  z-index: 999;
  background: rgba(0,0,0,1);

  backdrop-filter: blur(5px) brightness(0.9);
}

.header-component-m {
  //position: fixed;
  //top: 0;
  //left: 0;
  width: 100vw;
  z-index: 999;
  background: rgba(0,0,0,1);
}
.header-component-bg {
  background: rgba(0,0,0,.4);
  backdrop-filter: blur(5px) brightness(0.9);
}
.header-component-bg-m {
  background: linear-gradient( 180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%);
  backdrop-filter: blur(5px) brightness(0.9);
}
</style>
