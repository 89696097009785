import {CheckMobileDevice} from '../utils/handle'
import '../../trusted-security-policies';
import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import IndexView from '../views/CCIndex.vue'
import {productList} from "@/api";
// const IndexView = () =>  import('../views/CCIndex.vue')
const winnerTakes = () => import('../views/pc/winnerTakes.vue')
// import winnerTakes from '../views/pc/winnerTakes.vue'
const Adventure = () => import('../views/pc/adventure.vue')
const AiCard = () =>  import('../views/pc/AiCard.vue')
const Tailor =  () => import('../views/pc/tailor.vue')
const WestGame = () =>  import('../views/pc/westGame.vue')
const Wilderness = () =>  import('../views/pc/Wilderness.vue')
const mobileWinnerTakes = () =>  import('../views/mobile/winnerTakes.vue')
const mobileAdventure = () =>  import('../views/mobile/adventure.vue')
const mobileAiCard = () =>  import('../views/mobile/AiCard.vue')
const mobileTailor = () =>  import('../views/mobile/tailor.vue')
const mobileWestGame = () =>  import('../views/mobile/westGame.vue')
const mobileWilderness = () =>  import('../views/mobile/Wilderness.vue')
const Catalogue = () =>  import('../views/ohter/catalogue.vue')
const UserAgreement = () =>  import('../views/protocol/userAgreement.vue')
const PrivacyPolicy = () =>  import('../views/protocol/privacyPolicy.vue')
const manual = () =>  import('../views/ohter/manual.vue')
const product = () =>  import('../views/product/index.vue')



const routes: Array<RouteRecordRaw> = [
    { path: '/', name: '/', component:  () => import('../views/home/index.vue') },
    { path: '/index', name: 'index', component: IndexView},
    { path: '/home', name: 'home', component:  () => import('../views/home/index.vue')},
    { path: '/product', name: 'product', component: product},
    { path: '/news', name: 'news', component: () => import('../views/news/index.vue')},
    { path: '/team', name: 'team', component: () => import('../views/team/index.vue')},
    { path: '/contact', name: 'contact', component: () => import('../views/contact/index.vue')},

    { path: '/win-or-die', name: 'win-or-die',  component: winnerTakes},
    { path: '/come-on-bite-me!', name: 'come-on-bite-me!', component: Adventure},
    { path: '/aiCard', name: 'aiCard', component: AiCard},
    { path: '/tailor-master', name: 'tailor-master', component: Tailor},
    { path: '/safari-witness', name: 'safari-witness', component: Wilderness},
    { path: '/journey-to-the-west', name: 'journey-to-the-west', component: WestGame},
    // { path: '/contact', name: 'contact', component: contact},

    { path: '/win-or-die-m', name: 'win-or-die-m', component: mobileWinnerTakes},
    { path: '/come-on-bite-me!-m', name: 'come-on-bite-me!-m', component: mobileAdventure},
    { path: '/mobileAiCard', name: 'mobileAiCard', component: mobileAiCard},
    { path: '/tailor-master-m', name: 'tailor-master-m', component: mobileTailor},
    { path: '/journey-to-the-west-m', name: 'journey-to-the-west-m', component: mobileWestGame},
    { path: '/safari-witness-m', name: 'safari-witness-m', component: mobileWilderness},
    { path: '/catalogue', name: 'catalogue', component: Catalogue },
    { path: '/userAgreement', name: 'userAgreement', component: UserAgreement },
    { path: '/PrivacyPolicy', name: 'PrivacyPolicy', component: PrivacyPolicy },
    // { path: '/contact-m', name: 'contact-m', component: mobileContact},

    { path: '/manual', name: 'manual', component: manual },
    // 取消订阅
    { path: '/unsubscribe', name: 'unsubscribe', component: () =>  import('../views/unsubscribe/index.vue') },
    // { path: '/unsubscribe_m', name: 'unsubscribe_m', component: unsubscribe_m },
    // 取消订阅成功
    { path: '/unsubscription_successful', name: 'unsubscription_successful', component:  import('../views/unsubscription_successful/index.vue') },
    // { path: '/unsubscription_successful_m', name: 'unsubscription_successful_m', component: unsubscription_successful_m },
    // 订阅成功页
    { path: '/subscription_successful/:uuid', name: 'subscription_successful', component:  () =>  import('../views/subscription_successful/index.vue') },
    // { path: '/subscription_successful_m/:uuid', name: 'subscription_successful_m', component: subscribe_m },


    // 二级页面
    // 历史遗留路径
    {
        path: '/wod',
        name: 'wod',
        redirect: (to => { return { path: '/' } }),
    },
]


const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0 };
        }
    },
})



let registerRouteFresh = true
router.beforeEach(async (to, from, next) => {
    const path = to.fullPath.indexOf('?') > -1 ? to.fullPath.split('?')[0]: to.fullPath
    const filterRouter = router.options.routes.filter((item) => item.path == path)
    if(filterRouter.length > 0) {
        if(CheckMobileDevice()) {
            switch (path) {
                case '/safari-witness':
                    next('/safari-witness-m');
                    break;
                case '/win-or-die':
                    next('/win-or-die-m');
                    break;
                case '/come-on-bite-me!':
                    next('/come-on-bite-me!-m');
                    break;
                case '/aiCard':
                    next('/mobileAiCard');
                    break;
                case '/tailor-master':
                    next('/tailor-master-m');
                    break;
                case '/journey-to-the-west':
                    next('/journey-to-the-west-m');
                    break;
                // case '/unsubscribe':
                //     next('/unsubscribe_m');
                //     break;
                // case '/unsubscription_successful':
                //     next('/unsubscription_successful_m');
                //     break;
                default:
                    next()
            }
        }else {
            switch (path) {
                case '/safari-witness-m':
                    next("/safari-witness");
                    break;
                case '/win-or-die-m':
                    next("/win-or-die");
                    break;
                case '/come-on-bite-me!-m':
                    next("/come-on-bite-me!");
                    break;
                case '/mobileAiCard':
                    next("/aiCard");
                    break;
                case '/tailor-master-m':
                    next("/tailor-master");
                    break;
                case '/journey-to-the-west-m':
                    next("/journey-to-the-west");
                    break;
                // case '/unsubscribe_m':
                //     next('/unsubscribe');
                //     break;
                // case '/unsubscription_successful_m':
                //     next('/unsubscription_successful');
                //     break;
                default:
                    next()
            }
        }
    }else {
        let res: any = await productList({})
        const arr: any[] = []
        res.data.list.filter((value, index) => {
            arr.push({
                name: value.product_link,
                path: '/' + value.product_link,
                component: () => import('@/views/product/index.vue'),
            })
        })
        // 如果首次或者刷新界面，next(...to, replace: true)会循环遍历路由，如果to找不到对应的路由那么他会再执行一次beforeEach((to, from, next))直到找到对应的路由，我们的问题在于页面刷新以后异步获取数据，直接执行next()感觉路由添加了但是在next()之后执行的，所以我们没法导航到相应的界面。这里使用变量registerRouteFresh变量做记录，直到找到相应的路由以后，把值设置为false然后走else执行next(),整个流程就走完了，路由也就添加完了。
        if (registerRouteFresh) {
            arr.forEach((value, index) => {
                router.addRoute(value)
            })
            next({...to, replace: true})
            registerRouteFresh = false
        }else {
            next()
        }
    }

});




// alert(1)
// productList({}).then((res) => {
//     const list:any[] = res.data.list || []
//     list.forEach(((item: any) => {
//         router.addRoute({
//             path: `/${item.product_link}`,
//             name: item.product_link,
//             component: () => import('@/views/product/index.vue')
//         });
//     }))
// })
// console.log(router)


export default router
// router.addRoute({
//     path: `/products`,
//     name: 'products',
//     component: () => import('@/views/product/index.vue')
// });