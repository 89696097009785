import axios from 'axios'
import store from "../store";

import dotenv from 'dotenv';
dotenv.config();

console.log( )
const apiUrl = process.env.VUE_APP_BASE_URL;
const service = axios.create({
    baseURL:  apiUrl, // url = base url + request url
    timeout: 5000
    // withCredentials: true // send cookies when cross-domain requests
})


// Request interceptors
service.interceptors.request.use(
    (config) => {
        // Add X-Access-Token header to every request, you can add other custom headers here
        if (store.getters.GET_TOKEN) {
            config.headers['Authorization'] = store.getters.GET_TOKEN
        }
        return config
    },
    (error) => {
        Promise.reject(error)
    }
)

// Response interceptors
service.interceptors.response.use(
    (response) => {

        const res = response.data
        if (res.code !== 200) {
            // ElMessage({
            //     message: res.msg || 'Error',
            //     type: 'error',
            //     duration: 5 * 1000
            // })
            if (res.code === 2003 || res.code === 2005) {
                store.commit('REMOVE_TOKEN')
                window.location.hash = '/login'
            }
            return Promise.reject(res.msg)
        } else {
            return response.data
        }
    },
    (error) => {
        // ElMessage({
        //     message: error.msg,
        //     type: 'error',
        //     duration: 5 * 1000
        // })
        return Promise.reject(error)
    }
)

export default service
