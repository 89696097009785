import request from '../utils/request'


// 桌游列表
export const productList = (params: any) =>
    request({
        url: '/api/product/list',
        method: 'POST',
        data: params
    })
// 桌游列表详情
export const productDetail = (params: any) =>
    request({
        url: '/api/product/detail',
        method: 'POST',
        data: params
    })

// 新闻列表详情
export const newsList = (params: any) =>
    request({
        url: '/api/news/list',
        method: 'POST',
        data: params
    })
// 新闻埋点
export const newsPoint = (params: any) =>

    request({
        url: '/api/news/click',
        method: 'POST',
        data: params
    })



// 团队详情介绍列表
export const teamIntroduceList = (params: any) =>
    request({
        url: '/api/team/introduce/list',
        method: 'POST',
        data: params
    })
// 团队成员介绍列表
export const teamList = (params: any) =>
    request({
        url: '/api/team/list',
        method: 'POST',
        data: params
    })
// 邮箱订阅
export const addSubscription = (params: any) =>
    request({
        url: '/api/subscription/add',
        method: 'POST',
        data: params
    })

// 邮箱订阅列表
export const homeProductList = (params: any) =>
    request({
        url: '/api/home/product/list',
        method: 'POST',
        data: params
    })
// 邮件订阅埋点

export const emailPoint = (params: any) =>
    request({
        url: '/api/subscription/successful',
        method: 'POST',
        data: params
    })


// 合作伙伴

export const partnerList = (params: any) =>
    request({
        url: '/api/partner/list',
        method: 'POST',
        data: params
    })

// 添加合作商

export const addBusiness = (params: any) =>
    request({
        url: '/api/business/cooperation/add',
        method: 'POST',
        data: params
    })

